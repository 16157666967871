import { render, staticRenderFns } from "./donateList.vue?vue&type=template&id=200d4b8e&scoped=true"
import script from "./donateList.vue?vue&type=script&lang=js"
export * from "./donateList.vue?vue&type=script&lang=js"
import style0 from "./donateList.vue?vue&type=style&index=0&id=200d4b8e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200d4b8e",
  null
  
)

export default component.exports